import firebase from 'firebase/app'

import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

const VUE_APP_FIREBASE_API_KEY = 'AIzaSyC_C9KKHLxar62Vzqj8kpMis4AMjJPV35Q'
const VUE_APP_FIREBASE_AUTH_DOMAIN = 'joanna-simpson.firebaseapp.com'
const VUE_APP_FIREBASE_DATABASE_URL = 'https://joanna-simpson.firebaseio.com'
const VUE_APP_FIREBASE_PROJECT_ID = 'joanna-simpson'
const VUE_APP_FIREBASE_STORAGE_BUCKET = 'joanna-simpson.appspot.com'
const VUE_APP_FIREBASE_MESSAGING_SENDER_ID = '363408526380'
const VUE_APP_FIREBASE_APP_ID = '1:363408526380:web:0d7cff0a02a949c0775589'
const VUE_APP_FIREBASE_MEASUREMENT_ID = 'G-DRX6ELEKNF'

// firebase init goes here
const config = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DATABASE_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: VUE_APP_FIREBASE_APP_ID,
  measurementId: VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
firebase.initializeApp(config)
firebase.analytics()

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const awards = db.collection('awards')
const imageCollection = db.collection('images')
const videoCollection = db.collection('videos')
const about = db.collection('about')
const head = db.collection('head')
const skills = db.collection('skills')
const promo = db.collection('promo')
const gallery = db.collection('gallery')

// firebase collections

export {
  db,
  auth,
  storage,
  awards,
  imageCollection,
  videoCollection,
  about,
  head,
  skills,
  promo,
  gallery,
}
